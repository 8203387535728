import React from "react";
import MainLayout from "../../components/MainLayout/MainLayout";
import PageViews from "../../components/PageViews/PageViews";
import ClickBlock from "../../components/ClickBlock/ClickBlock";
import SessionBlock from "../../components/SessionBlock/SessionBlock";

const HomePage = () => {
  return (
    <div>
      <MainLayout title="Главная">
        <PageViews />
        <ClickBlock />
        <SessionBlock />
      </MainLayout>
    </div>
  );
};

export default HomePage;
